import {
  ReconnectingWebSocket
} from "./chunk-YV734IKE.mjs";

// src/index.ts
var valueIsNotNil = (keyValuePair) => keyValuePair[1] !== null && keyValuePair[1] !== void 0;
function generateUUID() {
  if (typeof crypto !== "undefined" && crypto.randomUUID) {
    return crypto.randomUUID();
  }
  let d = (/* @__PURE__ */ new Date()).getTime();
  let d2 = typeof performance !== "undefined" && performance.now && performance.now() * 1e3 || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : r & 3 | 8).toString(16);
  });
}
var PartySocket = class extends ReconnectingWebSocket {
  constructor(partySocketOptions) {
    const {
      host: rawHost,
      room,
      party,
      protocol,
      query,
      protocols,
      ...socketOptions
    } = partySocketOptions;
    const _pk = partySocketOptions.id || generateUUID();
    let host = rawHost.replace(/^(http|https|ws|wss):\/\//, "");
    if (host.endsWith("/")) {
      host = host.slice(0, -1);
    }
    const baseUrl = `${protocol || (host.startsWith("localhost:") || host.startsWith("127.0.0.1:") ? "ws" : "wss")}://${host}/${party ? `parties/${party}` : "party"}/${room}`;
    const makeUrl = (query2 = {}) => `${baseUrl}?${new URLSearchParams([
      ["_pk", _pk],
      ...Object.entries(query2).filter(valueIsNotNil)
    ])}`;
    const urlProvider = typeof query === "function" ? async () => makeUrl(await query()) : makeUrl(query);
    super(urlProvider, protocols, socketOptions);
    this.partySocketOptions = partySocketOptions;
    this._pk = _pk;
    this._pkurl = baseUrl;
    this.name = party ?? "main";
    this.room = room;
    this.host = host;
  }
  _pk;
  _pkurl;
  name;
  room;
  host;
  get id() {
    return this._pk;
  }
  /**
   * Exposes the static PartyKit room URL without applying query parameters.
   * To access the currently connected WebSocket url, use PartySocket#url.
   */
  get roomUrl() {
    return this._pkurl;
  }
};

export {
  PartySocket
};
